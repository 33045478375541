import React from 'react';
import PropTypes from 'prop-types';
import './Heading.css';

const Heading = props => {
  const { children } = props;
  return <h1 className="header">{children}</h1>;
};

Heading.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Heading;
