import React from 'react';

import SEO from '../components/seo';
import Content from '../components/Content';

const IndexPage = () => (
  <div>
    <SEO
      title="Download 100mentors.AI"
      description="Click to download the 100mentors.AI mobile app and get started right away!"
      keywords={[`gatsby`, `application`, `react`]}
    />
    <Content />
  </div>
);

export default IndexPage;
