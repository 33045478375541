import React from 'react';
import PropTypes from 'prop-types';
import './Link.css';

const Link = props => {
  const { href } = props;
  return (
    <a href={href} className="text-link">
      100mentors
    </a>
  );
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
};

export default Link;
